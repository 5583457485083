import { render, staticRenderFns } from "./changgou.vue?vue&type=template&id=355bff25&scoped=true"
import script from "./changgou.vue?vue&type=script&lang=js"
export * from "./changgou.vue?vue&type=script&lang=js"
import style0 from "./changgou.vue?vue&type=style&index=0&id=355bff25&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355bff25",
  null
  
)

export default component.exports
<template>
  <div class="changgou">
    <div class="list">
      <div class="list_T">
        <img src="../../assets/order/chang_pic.png" alt="" />
        <div class="position">
          <div class="yuexiao">月销5000件</div>
          <div class="yigou">已购买2次</div>
        </div>
      </div>
      <div class="list_B">
        <div class="title">
          菜籽油/脂肪酸标准物质两瓶装(GBW(E)100121)10克纯净0克纯净0克纯净
        </div>
        <div class="price">
          <div class="price_L">￥<span class="spen">320.00</span></div>
          <div class="price_R">
            <div class="jiaru_L">
              <img src="../../assets/order/gouwuche.png" alt="" />
            </div>
            <div class="jiarucart_R">{{ $t('button.Add_to_cart') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';

export default {
  data() {
    return {};
  },
  created(){
    this.getoftensku()
  },
  methods:{
     getoftensku() {
      get('api/orderbasic/getoftensku').then((res) => {
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.changgou {
  width: 975px;
  margin: 0px auto;
  padding-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  .list {
    width: 234px;
    height: 404px;
    border: 2px solid #f0f5f1;
    margin-top: 10px;
    margin-left: 5px;
    .list_T {
      width: 234px;
      height: 279px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .position {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #fff;
        .yuexiao {
          width: 114px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #9ba2a8;
        }
        .yigou {
          width: 115px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #9ba2a8;
        }
      }
    }
    .list_B {
      padding: 0 15px;
      .title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 20px;
        font-size: 14px;
        color: #333;
      }
      .price {
        height: 25px;
        line-height: 25px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .price_L {
          color: #e88021;
          font-size: 12px;
          .spen {
            font-size: 14px;
          }
        }
        .price_R {
          width: 120px;
          background: #1850ff;
          color: #fff;
          border-radius: 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .jiaru_L {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            img {
              width: 14px;
              height: 12px;
            }
          }
          .jiarucart_R {
            flex: 1;
            width: 70px;
            white-space: nowrap;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
